<template>
  <div>
    <Header />

    <div class="login-register_area">
      <div class="container">
        <div class="row col-xs-12">
          <div
            class="col-md-8 col-lg-8 col-sm-12 col-xs-12 center-block"
            style="margin: auto;"
          >
            <form action class="form" @submit.prevent="signup">
              <div class="login-form">
                <h4
                  class="login-title"
                  style="text-transform: inherit;text-align: center;"
                >
                  Ingresa tu correo electrónico para solicitar el código de
                  recuperación
                </h4>
                <div class="row">
                  <div class="col-md-12">
                    <label>Email *</label>
                    <input
                      @input="checkEmail()"
                      v-model="email"
                      id="email"
                      required
                      type="email"
                      placeholder="Ingresa tu correo electrónico"
                    />
                  </div>

                  <div class="col-md-12">
                    <button type="submit" class="btn ht-btn--round">
                      Enviar código
                    </button>
                    <p v-if="error" style="padding-top: 10px;" class="error">
                      Ocurrió un error, intente de nuevo por favor.
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <BrandCarousel />

    <Footer />

    <OffCanvasMobileMenu />

    <!-- back to top start -->
    <back-to-top class="scroll-top" bottom="60px">
      <i class="ion-android-arrow-up"></i>
    </back-to-top>
    <!-- back to top end -->
  </div>
</template>

<script>
import Header from "@/components/Header";
import BrandCarousel from "../components/BrandCarousel";
import Footer from "../components/Footer";
import OffCanvasMobileMenu from "@/components/OffCanvasMobileMenu";
import axios from "axios";

var urlRecoveryCreate = process.env.VUE_APP_AUTH_API + "api/password/create";
export default {
  name: "Recovery",
  data: () => ({
    email: "",
    error: false
  }),
  components: {
    Header,
    BrandCarousel,
    Footer,
    OffCanvasMobileMenu
  },
  methods: {
    checkEmail() {},
    signup() {
      axios
        .post(urlRecoveryCreate, {
          email: this.email
        })
        .then(response => {
          console.log(response.data);
          this.$router.push("/contrasena");
        })
        .catch(error => {
          console.log(error);
          this.error = true;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.ht-btn--round {
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 20px;
  font-size: 20px;
  background-color: #003d74;
}
.login-form input {
  padding: 6px;
}
</style>